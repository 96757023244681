import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w10HH03OmasTipps = () => (
  <Layout>
    <SEO title="w10HH03OmasTipps" />

    <h1 id="ueberschrift">Haushalt</h1>
    <h2>Omas Tipps und Tricks &emsp;  ©&nbsp;1997</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w10HH03OmasTipps&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Welche Eigenschaften muss eine gute Hausfrau haben?"<br />
        "Sie muss Kochen, Spülen und Staubsaugen zum Hobby ihres Mannes machen."
        <div style={{fontSize: "0.75em"}}>aus einem schwedischen Frauen-Magazin</div>
      </p>

    <p>---</p>

      <p>
        Ob Fleckenentfernung, Reinigungstipps, "Hausmedizin", etc. erzählen Sie 
        bei einem gemütlichen Beisammensein von Ihren Erlebnissen, Niederlagen und Erfolgen mit den 
        Tipps und Tricks aus "Omas Nähkästchen". Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten die Zeit genießen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig + <br/>
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w10HH03OmasTipps
